export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'SideBarNavItem',
        name: 'Дашбоард',
        to: '/dashboard',
        icon: 'cilPuzzle',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'SideBarNavItem',
        name: 'Акты',
        to: '/acts',
        icon: 'cilStar'
      },
      {
        _name: 'SideBarNavItem',
        name: 'Поверки',
        to: '/meters',
        icon: 'cilDrop'
      },
      {
        _name: 'SideBarNavItem',
        name: 'Статистика',
        to: '/statistic',
        icon: 'cilChartPie'
      },
      {
        _name: 'SideBarNavItem',
        name: 'Профиль',
        to: '/profile',
        icon: 'cilUser'
      },

    ]
  }
]
