




























import { defineComponent, ref } from '@vue/composition-api'

export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42
    }
  },
  setup (props, ctx) {
    const store = ctx.root.$store

    const logout = () => {
      store.dispatch('AUTH_LOGOUT')
    }
    return { logout }
  }
}
